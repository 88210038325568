<template>
	<div class="login-container">
		<div class="header"></div>

		<el-card class="login-form-container">
			<el-form
				ref="loginForm"
				class="login-form"
				:model="loginForm"
				:rules="loginRules"
				auto-complete="on"
				label-position="left">
				<div class="title-container text-center">
					<div class="logo">
						<img src="../../../assets/images/logo.jpg" />
					</div>
					<b>2021 - 2022</b>
				</div>

				<el-divider></el-divider>

				<el-form-item prop="username">
					<el-input
						ref="username"
						v-model="loginForm.username"
						:placeholder="$t('username')"
						name="username"
						tabindex="1"
						auto-complete="on"
						@keyup.enter="login"
						prefix-icon="user"
						size="large">
					</el-input>
				</el-form-item>

				<el-form-item prop="password">
					<el-input
						ref="password"
						v-model="loginForm.password"
						:placeholder="$t('password')"
						name="password"
						tabindex="2"
						auto-complete="on"
						@keyup.enter="login"
						show-password
						prefix-icon="lock"
						size="large">
					</el-input>
				</el-form-item>

				<div class="text-center mb-10">
					<el-checkbox
						v-model="rememberMe"
						:label="$t('rememberMe')"
						size="default"></el-checkbox>
				</div>

				<el-button
					:loading="loading"
					type="primary"
					class="login-btn mt-10"
					@click.prevent="login">
					{{ $t('logIn') }}
				</el-button>
			</el-form>
		</el-card>

		<div class="footer">
			<span class="copyright"> &copy; {{ new Date().getFullYear() }} </span>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapMutations, mapActions } from 'vuex'

	import { Routes, AppScopes, Roles } from '@/utils/enums'

	export default {
		name: 'Login',
		data() {
			const validateUsername = (rule, value, callback) => {
				if (value) callback()
				else callback(new Error(this.$t('idRequired')))
			}
			const validatePassword = (rule, value, callback) => {
				if (value) callback()
				else callback(new Error(this.$t('passwordRequired')))
			}
			return {
				loginForm: {
					username: '',
					password: '',
				},
				loginRules: {
					username: [
						{ required: true, trigger: 'blur', validator: validateUsername },
					],
					password: [
						{ required: true, trigger: 'blur', validator: validatePassword },
					],
				},
				rememberMe: false,
				loading: false,
			}
		},
		created() {
			if (this.isUserConnected) this.$router.replace(Routes.HOME.PATH)
		},
		computed: {
			...mapGetters({
				isUserConnected: 'session/isUserConnected',
			}),
		},
		methods: {
			...mapMutations({
				updateUserData: 'session/updateUserData',
				updateConnected: 'session/updateConnected',
				updateRememberMe: 'session/updateRememberMe',
				updateCurrentAppScope: 'globals/updateCurrentAppScope',
			}),
			...mapActions({
				fetchTeachersDB: 'teachers/fetchTeachersDB',
				getUserByCredentials: 'authentication/getUserByCredentials',
			}),
			async login() {
				this.$refs.loginForm.validate(async (valid) => {
					if (valid) {
						this.loading = true

						let resp = await this.getUserByCredentials({
							username: this.loginForm.username,
							password: this.loginForm.password,
						})

						if (resp.success) {
							if (resp.data.username && resp.data.password && resp.data.user) {
								this.updateConnected(true)
								this.updateRememberMe(this.rememberMe)

								let user = this.$_.cloneDeep(resp.data.user)

								switch (parseInt(resp.data.user.role)) {
									case Roles.SUPERADMIN:
										this.updateCurrentAppScope(AppScopes.ADMIN)
										break

									case Roles.ADMIN:
										this.updateCurrentAppScope(AppScopes.ADMIN)
										break

									case Roles.TEACHER:
										let teacher = await this.fetchTeachersDB({
											action: 'getTeacherByUser',
											params: {
												user: resp.data.user.id,
												schoolyear: this.currentSchoolyear,
											},
										})

										user.roleData =
											teacher.success && teacher.data ? teacher.data : null

										this.updateCurrentAppScope(AppScopes.TEACHER)
										break

									case Roles.STUDENT:
										this.updateCurrentAppScope(AppScopes.STUDENT)
										break

									case Roles.PARENT:
										this.updateCurrentAppScope(AppScopes.PARENT)
										break

									default:
										this.updateCurrentAppScope(AppScopes.GLOBAL)
										break
								}

								this.updateUserData(user)

								this.$router.replace(Routes.HOME.PATH)
							} else if (resp.data.username) {
								this.$message.error(this.$t('wrongPassword'))
							} else {
								this.$message.error(this.$t('userDontExist'))
							}
						} else {
							this.$message.error(this.$t('anErrorOccured'))
						}

						this.loading = false
					} else {
						return false
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.login-container {
		min-height: 100%;
		width: 100%;
		background-color: var(--secondary-color);
		overflow: hidden;
		.login-form-container {
			position: relative;
			max-width: 100%;
			width: 500px;
			margin: 0 auto;
			overflow: hidden;
			margin-top: 25px;
		}
		::v-deep(.login-form-container .el-card__body) {
			background-color: ghostwhite;
			padding-right: 40px;
			padding-left: 40px;
		}
		.login-form {
			background-color: white;
			padding-right: 25px;
			padding-left: 25px;
		}
	}

	.login-btn {
		width: 100%;
		margin-bottom: 30px;
	}

	.logo {
		text-align: center;
	}

	.logo img {
		width: 150px;
	}

	::v-deep(::placeholder, ::-ms-input-placeholder) {
		color: gray;
		opacity: 1;
	}

	.footer {
		bottom: 0;
		width: 100%;
		height: 50px;
		position: fixed;
		background-color: var(--secondary-color);
		justify-content: center;
		align-items: center;
		display: flex;
		z-index: 9999;
	}

	.header {
		top: 0;
		height: 0;
		width: 100%;
		z-index: 9999;
		position: fixed;
		background-color: var(--secondary-color);
	}

	.copyright {
		color: white;
	}
</style>
